import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    Promise.all([
      import(/* webpackChunkName: "splide" */ "@splidejs/splide"),
      import(/* webpackChunkName: "splide" */ "@splidejs/splide/dist/css/splide.min.css")
    ]).then(results => {
      const Splide = results[0].default;

      new Splide(this.element, {
        type: 'fade',
        rewind: true,
      }).mount();
    });
  }
}
