import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    if(document.readyState !== "ready")
      document.addEventListener('readystatechange', event => {
        if (event.target.readyState === "complete")
          this.make();
      });
    else this.make();
  }

  make () {
    import(/* webpackChunkName: 'masonry' */ "masonry-layout").then(r => {
      const Masonry = r.default;
      new Masonry(this.element, {
        itemSelector: ".masonry-item",
        horizontalOrder: true,
        columnWidth: ".masonry-sizer"
      });
    });
  }
}
