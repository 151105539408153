import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "scroll", "left", "right" ];
  static classes = [ "invisible" ];

  connect () {
    this.display_controls();
  }

  scroll_left () {
    this.scroll(-1);
  }

  scroll_right () {
    this.scroll(1);
  }

  scroll (direction) {
    this.scrollTarget.scroll({
      left: this.scrollTarget.scrollLeft + direction * this.scrollTarget.getBoundingClientRect().width / 2,
      behavior: "smooth"
    });

    this.display_controls();
  }

  display_controls () {
    window.setTimeout(() => {
      this.leftTarget.classList[this.scrollTarget.scrollLeft < 4 ? "add" : "remove"](this.invisibleClass);
      this.rightTarget.classList[this.scrollTarget.scrollLeft > this.scrollTarget.scrollLeftMax - 4 ? "add" : "remove"](this.invisibleClass);
    }, 500);
  }
};
