import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "image" ];

  connect () {
    if(document.readyState !== 'loading')
      this.onload();
  }

  onload () {
    if(this.data.has("loaded")) return;
    this.imageTarget.src = this.data.get("src");
    this.data.set("loaded", true);
  }
}
