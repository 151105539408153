import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "debug" ]
  static values = {
    redirect: String
  }

  connect () {
    if(this.hasDebugTarget && window.location.hash.indexOf("debug") >= 0)
      for(let d of this.debugTargets)
        d.classList.remove("hidden");
  }

  submit (e) {
    e.preventDefault();

    const url = document.body.dataset.debug ? "http://localhost:3000/form" : "https://cp.mesto.world/form";

    fetch(url, {
      method: "POST",
      body: new FormData(e.target)
    }).then(r => r.json())
      .then(r => {
        if(r.response == "Success") {
          if(this.hasRedirectValue) {
            window.location.href = this.redirectValue;
          }
          else {
            this.element.reset();
            alert("Успешно отправлено!");
            this.element.dispatchEvent(new CustomEvent('submitted'));
          }
        }
        else {
          console.log(r);
          throw "Wrong server response";
        }
      })
      .catch(e => alert("Ошибочка вышла... Напишите нам напрямую."));
  }
};
