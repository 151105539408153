import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  toggle_modal () {
    this.modalTarget.classList.toggle("hidden");
  }

  close_modal () {
    this.modalTarget.classList.add("hidden");
  }
};
