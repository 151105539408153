import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "image", "modal", "link", "previews", "modalImage" ];
  static classes = [ "preview", "activePreview" ];

  show (e) {
    this.set_image(e.target);
  }

  set_image (node) {
    const src = this.get_src(node);
    this.modalImageTarget.style["background-image"] = `url(${src})`;
    this.modalTarget.classList.remove("hidden");
    this.linkTarget.href = src;

    let targetNode = this.imageTargets.find(n => n.contains(node));
    let index = this.imageTargets.indexOf(targetNode);
    this.data.set("index", index);

    this.build_previews();
  }

  get_src (node) {
    return (node.nodeName == "IMG" ? node : node.querySelector("img")).src;
  }

  build_previews () {
    const index = parseInt(this.data.get("index")) || 0;

    this.previewsTarget.innerHTML = "";

    for(let i in this.imageTargets) {
      let node = this.imageTargets[i];
      let div = document.createElement("div");
      div.style["background-image"] = `url('${this.get_src(node)}')`;
      div.style["background-size"] = "cover";
      div.dataset.index = i;
      div.classList = "w-16 h-16 border-2 " + (i == index ? this.activePreviewClass : this.previewClass);
      this.previewsTarget.appendChild(div);

      div.addEventListener("click", e => this.set_image(this.imageTargets[e.currentTarget.dataset.index]));
    }
  }

  close (e) {
    e.stopPropagation();
    this.modalTarget.classList.add("hidden");
  }

  previous () {
    this.update_image(-1);
  }

  next () {
    this.update_image(1);
  }

  update_image (i) {
    let index = (parseInt(this.data.get("index")) || 0) + i,
        length = this.imageTargets.length;

    if(index < 0) index = length - 1;
    if(index >= length) index = 0;

    this.set_image(this.imageTargets[index]);
  }
};
