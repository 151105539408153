import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    this.data.set("time", window.performance.now());

    window.setTimeout(() => {
      this.make_marks();
      window.setInterval(() => fetch(this.url() + "&time=" + (window.performance.now() - this.data.get("time"))), 10000);

      const observer = new ResizeObserver((entries, observer) => {
        if(this.should_make_marks) return;
        this.should_make_marks = true;
        window.setTimeout(() => {
          this.should_make_marks = false;
          this.make_marks();
        }, 1000);
      });
      observer.observe(document.querySelector("main"));
    }, 500);
  }

  make_marks () {
    if(this.marks)
      for(let m of this.marks)
        m.remove();

    this.marks = [];

    const height = this.element.getBoundingClientRect().height,
          screen_height = Math.max(400, window.screen.availHeight),
          callback = (entries, observer) => {
            if(entries[0].intersectionRatio == 1)
              fetch(this.url() + "&scroll=" + entries[0].target.dataset.scroll);
          };

    let h = 0,
        i = 0;
    while(h < height) {
      let mark = document.createElement("div");
      mark.style.width = "1px";
      mark.style.height = "1px";
      mark.style.position = "absolute";
      mark.style.left = "0";
      mark.style.top = `${h}px`;
      mark.style.background = "red";
      mark.style["z-index"] = "-1";
      mark.style.opacity = "0";
      this.element.appendChild(mark);

      h += screen_height;
      i++;

      const observer = new IntersectionObserver(callback, {threshold: 1.0});
      observer.observe(mark);

      this.marks.push(mark);
    }

    for(let i in this.marks) {
      this.marks[i].dataset.scroll = i + "/" + this.marks.length;
    }
  }

  url () {
    return this.data.get("url") + "?page=" + encodeURIComponent(window.location.href);
  }

  log (e) {
    fetch(this.url() + "&event=" + encodeURIComponent(e.target.dataset.event));
  }
};
